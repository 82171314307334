import './App.css';
import Router from './Router.js'

function App() {
  return (
      <Router/>
  );
}

export default App;
