import React from 'react'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import Text from "../components/Workoutplans";


export default function Workoutplan() {
    return (
        <div>
            <Sidebar />
            <Text />
            <Footer />
        </div>
    )
}